import * as React from 'react';
import { useEffect } from 'react';
import { useIsClientSide } from '../../../../utils/hooks';

export interface HeaderHelperConfiguration {
    enableRedirectOnCookie?: Boolean;
    pageToRedirectOn?: string;
    redirectsOnFrontPage?: redirectObject[];
    cookieExpiration?: number;
    shouldRedirectToLocale?: Boolean;
    shouldLogOutInvalidLocaleUser?: Boolean;
    telemetry?: any;
}

interface redirectObject {
    mappingToUrl: urlObject;
    mappedFrom: string[];
}

interface urlObject {
    destinationUrl: string;
    type: string;
}

export interface HeaderHelperProps {
    config: HeaderHelperConfiguration; 
    request: any; //is any since not exposed from msdynamics modules
}

export const HeaderHelper = ({ config, request }: HeaderHelperProps) => {
    return (
        <>
            <CookieHandlerHelper config={config} request={request} />
            <LocaleRedirectHelper config={config} request={request} />
        </>
    );
};

const CookieHandlerHelper = ({ config, request }: HeaderHelperProps) => {
    const isClient = useIsClientSide();
    const { enableRedirectOnCookie = false, pageToRedirectOn = '', redirectsOnFrontPage = [], cookieExpiration = 0} = config;
    useEffect(() => {
        //Clause guards
        if (!isClient) {
            return;
        }

        if (!enableRedirectOnCookie) {
            return;
        }
        
        const { pathname } = window.location;
        const cookieName = 'onlyGenderCategory';
        if (pathname === pageToRedirectOn) {
            //If cookie exists, redirect to the page
            if(getCookie(cookieName) !== ''){
                const cookieContent = getCookie(cookieName);
                window.location.replace(cookieContent);
            }
        } else{
            //Set cookie
            const subPaths = pathname.split('/').filter(path => path != '');

            const mappingTo = redirectsOnFrontPage.find(redirect =>
                redirect.mappedFrom.some(fromMapping => subPaths.some(subPath => subPath == fromMapping))
            );
            if (mappingTo == undefined) {
                return;
            }

            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + cookieExpiration);
            const expirationDateInUtc = currentDate.toUTCString();
            clearCookie(cookieName);
            document.cookie = `${cookieName}=${mappingTo.mappingToUrl.destinationUrl}; expires=${expirationDateInUtc};`;
            return;
        }
    }, [isClient]);
    return <></>;
};

const LocaleRedirectHelper = ({ config, request }: HeaderHelperProps) => {
    const isClient = useIsClientSide();
    const { shouldRedirectToLocale = false, shouldLogOutInvalidLocaleUser = false, cookieExpiration = 0} = config;
    useEffect(() => {
        //Clause guards
        if (!isClient) {
            return;
        }
        const cookieName = '_msdyn365___site_';

        if(shouldRedirectToLocale) {
            const cookieContent = getCookie(cookieName);
            if(cookieContent !== '' && window.location.pathname === '/') {
                window.location.replace(cookieContent);
            }
        } else {
            //If it should not redirect to locale, then we need to save the locale in a cookie
            var previousCookie = getCookie(cookieName);
            var path = window.location.pathname;
            const subPaths = path.split('/').filter(pathname => pathname != '');
            if(subPaths.length > 1) {
                path = '/' + subPaths[0];
            }

            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + cookieExpiration);
            const expirationDateInUtc = currentDate.toUTCString();
            clearCookie(cookieName);
            document.cookie = `${cookieName}=${path}; expires=${expirationDateInUtc}; path=/;`;

            //If the user is logged in on an invalid locale
            if(shouldLogOutInvalidLocaleUser && request.user.isAuthenticated && previousCookie && previousCookie !== path) {
                if(config.telemetry) {
                    //TODO: Should be information telemetry
                    config.telemetry.warning('User logged in on invalid locale. Logging out user.');
                }
                window.location.href = request.user.signOutUrl;
            }
        }
    }, [isClient]);
    //TODO: Also run on isConsentGiven change?
    return <></>;
};

const getCookie = (cname: string) => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
const clearCookie = (cookieName: string) => (document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`);
